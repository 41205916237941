/**
 * It will leave a text string ready to be compared, making it lowercase 
 * and removing its accent marks.
 * @param {string} string
 */
export default function normalizeString(string) {
  return string
    .toLowerCase()
    .replace(new RegExp(/á|é|í|ó|ú/g), (match) => replacements[match]);
}
const replacements = {
  á: "a",
  é: "e",
  í: "i",
  ó: "o",
  ú: "u",
};
