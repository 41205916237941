import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import Button from "@mui/material/Button";

import Typography from "@mui/material/Typography";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PhoneEnabledIcon from "@mui/icons-material/PhoneEnabled";
import InsertLinkOutlinedIcon from "@mui/icons-material/InsertLinkOutlined";
import LocationOnIcon from "@mui/icons-material/LocationOn";

import { Skeleton, useMediaQuery } from "@mui/material";

import BlankState from "../components/BlankState";
import NoResults from "../components/NoResults";
import Loading from "../components/Loading";
import Navbar from "../components/Navbar";
import Selection from "../components/Selection";
import ResultCard from "../components/ResultCard";

import db from "../utils/firebase";
import { collection, query, getDocs } from "firebase/firestore";

import CircularProgress from "@mui/material/CircularProgress";
import preLoad from "../assets/img/PreLoad.jpg";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import { queryContext } from "../context/query";
import { useMemo } from "react";
import { asc, desc, getSortOptions } from "../utils/sorts";
import { FloatingSearchButton } from "../context/search/Provider";

const Results = () => {
  const [loadingResults, setLoadingResults] = useState(true);
  const queryCtx = useContext(queryContext);
  const [results, setResults] = useState([]);
  const history = useHistory();
  const sortHookState = useSorts();
  const { sortFn } = sortHookState;

  const [selectedOptionId, setSelectedOptionId] = useState(null);

  useEffect(() => {
    const getData = async () => {
      if (!queryCtx.constraints.department) return;
      setLoadingResults(true);
      const constraints = queryCtx.getQueryConstraints;
      const q = query(collection(db, "proposals-2023"), ...constraints);
      const querySnapshot = await getDocs(q);
      setResults(querySnapshot.docs.map((d) => ({ ...d.data(), id: d.id })));
    };

    getData()
      .catch(console.log)
      .finally(() => setLoadingResults(false));
  }, [queryCtx]);

  const selectOption = (proposal, i) => {
    if (window.innerWidth <= 1000) {
      history.push({
        pathname: `/details/${proposal.id}`,
        state: {
          proposal,
        },
      });
    } else {
      setSelectedOptionId(proposal.id);
    }
  };

  const selectedOption = useMemo(
    () =>
      results.length > 0
        ? results.find((proposal) => proposal.id === selectedOptionId)
        : null,
    [selectedOptionId, results]
  );

  const navigateBack = () => {
    history.push({
      pathname: "/",
    });
  };

  const theme = createTheme({
    palette: {
      primary: {
        main: "#7cb936",
      },
    },
  });

  const atLeastDepartmentSelected =
    Boolean(queryCtx.constraints.department) &&
    queryCtx.constraints.department !== "none";

  const sorted = useMemo(
    () => (!Boolean(results.length) ? [] : results.slice().sort(sortFn)),
    [results, sortFn]
  );

  if (!atLeastDepartmentSelected && !queryCtx.loadingConstraintsFromSession) {
    return (
      <>
        <Navbar color="green" />
        <div className="content-container">
          <div style={{ textAlign: "center" }}>
            <h2 className="selection-title" style={{ marginBottom: "3rem" }}>
              No podemos realizar la búsqueda sin filtros, vuelva a la página
              anterior y realice una nueva búsqueda
            </h2>
            <Button
              variant="contained"
              className="btn-green-about"
              onClick={navigateBack}
            >
              volver al inicio
            </Button>
          </div>
        </div>
      </>
    );
  }

  const allFinishLoading =
    !loadingResults &&
    !queryCtx.loadingConstraintsFromSession &&
    !queryCtx.loadingPreFilters;

  return (
    <>
      <Navbar color="green" />
      <FloatingSearchButton />
      <div className="content-container">
        <div className="left-container">
          <div className="selection-container">
            {!queryCtx.loadingConstraintsFromSession &&
            !queryCtx.loadingPreFilters &&
            Boolean(results.length) ? (
              <Selection
                proposalCount={results?.length}
                sortHookState={sortHookState}
              />
            ) : (
              <SelectionSkeleton />
            )}
          </div>
          <div className="results-container">
            {!allFinishLoading
              ? [...Array(10)].map((x, i) => <Loading key={i} />)
              : sorted?.map((result, i) => (
                  <ResultCard
                    key={result.id}
                    id={result.id}
                    selected={result.id === selectedOptionId}
                    selectThis={selectOption}
                    proposal={result}
                  />
                ))}
            {results.length === 0 &&
              atLeastDepartmentSelected &&
              allFinishLoading && <NoResults navigateBack={navigateBack} />}
          </div>
        </div>
        <div className="vertical-divider"></div>
        <div className="right-container">
          {Boolean(selectedOption) ? (
            <div className="slide">
              <h1 className="right-title">{selectedOption.proposalName}</h1>
              <h2 className="right-subtitle">
                {selectedOption.institutionName}
                {selectedOption.institutionName !== selectedOption.depends
                  ? ` - ${selectedOption.depends}`
                  : ""}
              </h2>
              <div className="divider-gray"></div>
              <div className="img-wraper">
                <img
                  src={selectedOption.urlPhoto}
                  alt="foto propuesta"
                  width="60%"
                  className="img-overlay"
                />
                <div className="circular-prog">
                  <ThemeProvider theme={theme}>
                    <CircularProgress color="primary" />
                  </ThemeProvider>
                </div>
              </div>
              <img
                src={preLoad}
                className="pre-load"
                alt="Imagen oculta de pre-load"
              />
              {selectedOption.description.split(". ").map((paragraph, i) => (
                <p key={i} className="description-paragraph">
                  {paragraph}
                </p>
              ))}
              <p className="description-li">
                <b>Duración: </b>
                {selectedOption.duration}
              </p>
              <p className="description-li">
                <b>Requisitos: </b>
                {selectedOption.requirements}
              </p>
              <li className="li">
                <div className="circle">
                  <LocationOnIcon className="white" />
                </div>
                <Typography className="contact center">
                  {selectedOption.address}
                </Typography>
              </li>
              <li className="li">
                <div className="circle">
                  <PhoneEnabledIcon className="white" />
                </div>
                <Typography className="contact center">
                  {selectedOption.tel}
                </Typography>
              </li>
              {selectedOption.mail && (
                <li className="li">
                  <div className="circle">
                    <MailOutlineIcon className="white" />
                  </div>
                  <Typography className="contact center">
                    <a href={`mailto:${selectedOption.mail}`}>
                      {selectedOption.mail}
                    </a>
                  </Typography>
                </li>
              )}
              {selectedOption.web && (
                <li className="li">
                  <div className="circle">
                    <InsertLinkOutlinedIcon className="white" />
                  </div>
                  <Typography className="contact center text-web">
                    <a
                      href={selectedOption.web}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {selectedOption.web}
                    </a>
                  </Typography>
                </li>
              )}
              <Button
                className="btn-green"
                variant="contained"
                color="success"
                onClick={navigateBack}
              >
                Volver al inicio
              </Button>
            </div>
          ) : (
            <BlankState />
          )}
        </div>
      </div>
    </>
  );
};

export default Results;

const sortFunctions = {
  proposalName: {
    asc: asc("proposalName"),
    desc: desc("proposalName"),
  },
  durationSort: {
    asc: asc("durationSort"),
    desc: desc("durationSort"),
  },
};

const sortOptions = getSortOptions(sortFunctions);

/** @type {SortState} */
const initialSortState = {
  onProperty: "proposalName",
  order: "asc",
};

function useSorts() {
  const [sort, setSort] = useState(initialSortState);

  /**
   * @param {PropertiesToSort} property
   */
  function sortOnProperty(property) {
    setSort((prev) => ({
      ...prev,
      onProperty: property,
    }));
  }

  /**
   *
   * @param {"asc" | "desc"} order
   */
  function sortByOrder(order) {
    setSort((prev) => ({
      ...prev,
      order,
    }));
  }

  return {
    sort,
    sortByOrder,
    sortOnProperty,
    sortOptions,
    sortFn: sortFunctions[sort.onProperty][sort.order],
  };
}

function SelectionSkeleton() {
  const isSmallScreen = useMediaQuery("(max-width: 505px)");
  return (
    <>
      <Skeleton variant="text" sx={{ fontSize: "1.5rem" }} />
      {isSmallScreen && (
        <Skeleton variant="text" width={140} sx={{ fontSize: "1.5rem" }} />
      )}
      <Skeleton
        variant="rectangular"
        height={40}
        width={120}
        style={{ marginTop: "2rem", marginBottom: "1rem" }}
      />
      <Skeleton
        variant="text"
        width={100}
        sx={{ fontSize: "1.5rem", marginBottom: "1rem" }}
      />
      <div className="chips-container">
        <div className="filters-grid">
          {Array.from({ length: 5 }).map((_, idx) => (
            <Skeleton
              variant="rectangular"
              height={55}
              style={{ borderRadius: 8 }}
              key={idx}
            />
          ))}
        </div>
      </div>
    </>
  );
}

/**
 * @typedef {"proposalName" | "durationSort"} PropertiesToSort
 *
 * @typedef {{
 *  onProperty: PropertiesToSort,
 *  order: "asc" | "desc"
 * }} SortState
 *
 * @typedef {ReturnType<typeof useSorts>} UseSortsReturnType
 */
