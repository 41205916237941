import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDe0ZY6wbno4gqp9qNojzhq-KpQKmeWq5s",
  authDomain: "mapaupm-c512a.firebaseapp.com",
  projectId: "mapaupm-c512a",
  storageBucket: "mapaupm-c512a.appspot.com",
  messagingSenderId: "433805392717",
  appId: "1:433805392717:web:a9ec23fe68306cc0c91504",
  measurementId: "G-PG95FC3MPC",
};

const app = initializeApp(firebaseConfig);

const db = getFirestore(app);

export default (app, db);
