import React from "react";
import { Typography, createTheme, ThemeProvider  } from "@mui/material";
import "./style.css";

const theme = createTheme();
theme.typography.body1 = {
  fontSize: "11px",
  color: "#888888",
  alignSelf: "center",
  '@media (max-width:899px)' : {
    fontSize: '10px'
  }
};

const Footer = ({ color }) => {
  return (
    <div className={`footer-${color}`}>
      <ThemeProvider theme={theme}>
        <Typography variant="body1" sx={{ marginBottom: "3px", fontWeight: 400 }}>
          Este sitio fue construido en conjunto por Fundación UPM y la empresa
          social de tecnología Matea, del Centro Educativo Providencia. 
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: "3px", fontWeight: 400 }}>
          Este sitio cuenta con el apoyo institucional del MEC y toda la oferta 
          educativa es tomada desde los portales públicos de las instituciones y 
          la información brindada por el Ministerio.
          </Typography>
      </ThemeProvider>
    </div>
  );
};

export default Footer;
