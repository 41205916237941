import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  Grid,
  Typography,
  createTheme,
  ThemeProvider,
  Paper,
} from "@mui/material";
import Navbar from "../components/Navbar";
import Filters from "../components/Filters";
import Carrousel from "../components/Carrousel";

import "../styles/styles.css";
//import logoMiFuturo from "../../src/assets/img/LogoMiFuturo.png";
import logoFundacion from "../../src/assets/img/LOGO_FUPM.svg";
import logoMatea from "../../src/assets/img/LogoMatea.png";
import logoMec from "../../src/assets/img/logoMec.png";

import Footer from "../components/Footer";
import { useContext, useState } from "react";
import { queryContext } from "../context/query";
import SearchIcon from "@material-ui/icons/Search";
import { searchCtx } from "../context/search";

const theme = createTheme();

theme.typography.h6 = {
  color: "black",
  fontSize: "1.1em",
  fontWeight: 500,
  marginBottom: "15px",
  marginTop: "15px",
  textAlign: "center",
  "@media screen and (max-width: 490px)": {
    fontSize: "11px",
  },
};

theme.typography.h5 = {
  color: "white",
  fontSize: "15px",
  fontWeight: 300,
  marginTop: "25px",
  marginBottom: "10px",
  textAlign: "center",
  "@media screen and (max-width: 490px)": {
    fontSize: "11px",
    padding: "0 4px",
  },
};

theme.typography.h4 = {
  color: "#2e7d32",
  fontWeight: 400,
  padding: 0,
  margin: 0,
  textAlign: "center",
  fontSize: "2em",
  "@media screen and (max-width: 490px)": {
    fontSize: "1.5em",
    padding: "0 4px",
  },
};

theme.typography.body1 = {
  fontSize: "14px",
  fontWeight: "normal",
};
theme.typography.body2 = {
  fontWeight: "normal",
  padding: "10px 0px",
};

const Index = (props) => {
  const history = useHistory();
  const queryCtx = useContext(queryContext);
  const { setIsModalOpen: setIsSearchModalOpen } = useContext(searchCtx);
  const [paperElevation, setPaperElevation] = useState(0);

  const nextPage = (e) => {
    if (
      queryCtx.constraints.department === null ||
      queryCtx.constraints.department === "none"
    ) {
      e.preventDefault();
      toast.error("Debes seleccionar un departamento!");
    } else {
      e.preventDefault();
      history.push({
        pathname: "/results",
      });
    }
  };

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover={false}
        limit={1}
      />
      <div className="bg">
        <Navbar color="white" selected="Búsqueda" showLogo />
        <Grid container columns={{ xs: 12, md: 12, xl: 12 }}>
          <Grid
            item
            xs={12}
            md={12}
            xl={12}
            display="flex"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
          >
            <div className="my-future">
              <ThemeProvider theme={theme}>
                {/* <img src={logoMiFuturo} alt="logo mi futuro" /> */}
                <Typography
                  variant="body1"
                  sx={{ color: "black", fontWeight: 600 }}
                >
                  ¡Comenzá a construir tu futuro hoy!
                </Typography>
                <Paper
                  component="button"
                  sx={{
                    p: ".75rem",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: 400,
                    maxWidth: "80%",
                    my: 2,
                    border: "none",
                    borderRadius: "30px",
                    cursor: "pointer",
                    "&:focus": {
                      outline: "1px solid #7cb936"
                    }
                  }}
                  elevation={paperElevation}
                  onClick={(_) => setIsSearchModalOpen(true)}
                  onFocus={(_) => setPaperElevation(3)}
                  onBlur={(_) => setPaperElevation(0)}
                >
                  <Typography variant="body1" color="GrayText">
                    Buscar propuesta por nombre
                  </Typography>
                  <SearchIcon htmlColor="grey" />
                </Paper>
                <Typography
                  variant="body1"
                  sx={{ color: "black", fontWeight: 600 }}
                >
                  O utiliza los filtros
                </Typography>
              </ThemeProvider>
            </div>
          </Grid>
          <Grid item xs={12} md={12} xl={12} display={{ xl: "flex" }}>
            <div className="filters">
              <Filters nextPage={nextPage} />
            </div>
          </Grid>
          <div className="divider"></div>
          <div className="carrousel">
            <ThemeProvider theme={theme}>
              <Typography
                variant="body1"
                sx={{ color: "white", fontWeight: 500, margin: "0 !important" }}
              >
                Tenemos algunas ideas para vos:
              </Typography>
            </ThemeProvider>
            <Grid
              item
              xs={12}
              md={12}
              xl={12}
              display="flex"
              justifyContent="center"
              alignItems="center"
              p={{ xs: 2, xl: 2 }}
              flexDirection={{ xs: "row", md: "column", xl: "column" }}
            >
              <Carrousel />
            </Grid>
          </div>
        </Grid>
        <div className="colaborationContainer">
          <img
            className="logoFundacion"
            src={logoFundacion}
            alt="logo fundacion upm"
          />
          <img className="logoMatea" src={logoMatea} alt="logo fundacion upm" />
          <img className="logoMec" src={logoMec} alt="logo mec" />
        </div>
      </div>
      <Footer color="white" />
    </>
  );
};

export default Index;
