import React from "react";

import empty from "../../assets/img/no_result.gif"
import "./styles.css";

const BlankState = () => {
  return (
    <div className="blankState">
        <h1>Todavía no seleccionaste una propuesta</h1>
        <img src={empty} alt="sin resultados"  width="100" />
        <p>Elegí la que más te interese en el menú de la izquierda para ver  los detalles</p>
    </div>
  );
};

export default BlankState;
