import { Box } from "@mui/material";

/**
 *
 * @param {import("react").PropsWithChildren<import("@mui/material").BoxProps>} props
 */
export default function ChipsSelectionList({ children, gap = 2, ...boxProps }) {
  return (
    <Box {...boxProps}>
      <Box
        display="flex"
        flexWrap="wrap"
        maxWidth="100%"
        gap={gap}
        alignItems="center"
      >
        {children}
      </Box>
    </Box>
  );
}
