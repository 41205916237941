import { searchCtx } from ".";

import {
  Box,
  CircularProgress,
  FormControl,
  IconButton,
  InputBase,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import Fab from "@mui/material/Fab";
import SearchIcon from "@mui/icons-material/Search";
import db from "../../utils/firebase";
import { useContext, useEffect, useMemo, useState } from "react";
import {
  collectionGroup,
  getDocs,
  limit,
  query,
  where,
} from "firebase/firestore";
import empty from "../../assets/img/no_result.gif";
import { Link, useLocation } from "react-router-dom";
import { alias, asc, desc, getSortOptions } from "../../utils/sorts";
import normalizeString from "../../utils";

const modalStyles = {
  position: "absolute",
  left: "50%",
  top: "50%",
  transform: "translate(-50%, -50%)",
  height: { xs: "85vh", md: "70vh" },
  backgroundColor: "white",
  borderRadius: "8px",
  overflow: "hidden",
};

export default function SearchModalProvider({ children }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searching, setSearching] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [results, setResults] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState("all");
  const [isQueryEmpty, setIsQueryEmpty] = useState(false);
  const { sort, sortByOrder, sortFn, sortOnProperty, sortOptions } = useSorts();
  const location = useLocation();

  function handleSubmit(e) {
    e.preventDefault();
    const trimmedValue = inputValue.trim();
    if (trimmedValue && !searching) {
      setSearching(true);
      setSelectedDepartment("all");
      const q = query(
        collectionGroup(db, "search"),
        where("searchArray", "array-contains", normalizeString(trimmedValue)),
        limit(200)
      );
      getDocs(q)
        .then((snap) => {
          if (snap.size === 0) {
            setIsQueryEmpty(true);
          } else setIsQueryEmpty(false);
          setResults(
            snap.docs.map((d) => {
              const docId = d.data().ref.path.split("/")[1];
              const docData = d.data();
              return {
                id: docId,
                proposalName: docData.proposalName,
                institutionName: docData.institutionName,
                urlAvatar: docData.urlAvatar,
                department: docData.department,
              };
            })
          );
        })
        .catch(console.error)
        .finally(() => setSearching(false));
    }
  }

  const sortedResults = useMemo(
    () =>
      results.length > 0
        ? results
            .filter(
              (r) =>
                selectedDepartment === "all" ||
                r.department === selectedDepartment
            )
            .sort(sortFn)
        : results,
    [results, sortFn, selectedDepartment]
  );

  const departments = useMemo(
    () => [...new Set(results.map(({ department }) => department))],
    [results]
  );

  useEffect(() => {
    setIsModalOpen(false);
  }, [location, setIsModalOpen]);

  const emptySearchProps = isQueryEmpty ? { message: "Sin resultados" } : {};
  return (
    <searchCtx.Provider
      value={{ inputValue, isModalOpen, searching, setIsModalOpen }}
    >
      {children}
      <Modal open={isModalOpen} onClose={(_) => setIsModalOpen(false)}>
        <Box
          width={{ xs: "90%", sm: "80%", lg: "50%" }}
          sx={modalStyles}
          display="flex"
          flexDirection="column"
        >
          <Box id="modal_header">
            <SearchBar
              handleSubmit={handleSubmit}
              setInputValue={setInputValue}
              searching={searching}
              inputValue={inputValue}
            />
          </Box>
          <Stack
            direction={{ xs: "column", md: "row" }}
            flexGrow={1}
            style={{ overflowY: "hidden" }}
          >
            <Box
              maxWidth={{ md: "30%" }}
              minWidth={{ md: "25%" }}
              my={2}
              mx={{ xs: 2, md: 0 }}
              pr={1}
              borderRight={{ md: "1px solid #ddd" }}
              borderBottom={{ xs: "1px solid #ddd", md: "none" }}
              id="modal_sidebar"
            >
              <FormControl fullWidth variant="filled" color="success">
                <InputLabel id="onProperty" sx={{ marginLeft: 0 }}>
                  Ordenar por:
                </InputLabel>
                <Select
                  onChange={(e) => sortOnProperty(e.target.value)}
                  value={sort.onProperty}
                  labelId="onProperty"
                  disableUnderline
                  style={{ backgroundColor: "transparent" }}
                >
                  {Object.keys(sortOptions).map((property) => (
                    <MenuItem key={property} value={property}>
                      {alias[property]}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth variant="filled" color="success">
                <InputLabel sx={{ marginLeft: 0 }}>Orden: </InputLabel>
                <Select
                  onChange={(e) => sortByOrder(e.target.value)}
                  value={sort.order}
                  style={{ backgroundColor: "transparent" }}
                  disableUnderline
                >
                  {sortOptions[sort.onProperty].map((order) => (
                    <MenuItem key={order} value={order}>
                      {alias[order]}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {departments.length > 0 && (
                <FormControl fullWidth variant="filled" color="success">
                  <InputLabel sx={{ marginLeft: 0 }} id="department">
                    Departamento:{" "}
                  </InputLabel>
                  <Select
                    onChange={(e) => setSelectedDepartment(e.target.value)}
                    value={selectedDepartment}
                    style={{ backgroundColor: "transparent" }}
                    labelId="department"
                    disableUnderline
                  >
                    <MenuItem value="all">Todos los departamentos</MenuItem>
                    {departments.map((department) => (
                      <MenuItem key={department} value={department}>
                        {department}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </Box>
            {searching && <EmptySearch message="Cargando..." />}
            {results.length > 0 && !searching && (
              <ResultsView results={sortedResults} />
            )}
            {results.length === 0 && !searching && (
              <EmptySearch {...emptySearchProps} />
            )}
          </Stack>
        </Box>
      </Modal>
    </searchCtx.Provider>
  );
}

export function FloatingSearchButton() {
  const { setIsModalOpen } = useContext(searchCtx);
  return (
    <Box position="fixed" bottom="1rem" right="1rem" zIndex={10}>
      <Fab
        onClick={() => setIsModalOpen(true)}
        style={{ borderRadius: "50%", backgroundColor: "#7cb936" }}
        sx={{
          "&:hover": {
            transform: "scale(1.05)",
          },
        }}
        aria-label="Buscar propuestas por nombre"
      >
        <SearchIcon style={{ color: "white" }} />
      </Fab>
    </Box>
  );
}

function SearchBar({ handleSubmit, setInputValue, searching, inputValue }) {
  return (
    <Paper
      component="form"
      sx={{
        py: "2px",
        display: "flex",
        alignItems: "center",
        mx: 2,
        boxSizing: "border-box",
        borderBottom: "1px solid #ddd",
      }}
      onSubmit={handleSubmit}
      elevation={0}
    >
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder="Buscar propuestas"
        inputProps={{ "aria-label": "Buscar propuestas" }}
        onChange={(e) => setInputValue(e.target.value)}
        value={inputValue}
      />
      <IconButton
        type="submit"
        sx={{ p: "10px" }}
        aria-label="search"
        disabled={searching}
      >
        {!searching ? (
          <SearchIcon />
        ) : (
          <CircularProgress style={{ color: "#7cb936" }} size="1rem" />
        )}
      </IconButton>
    </Paper>
  );
}

const sortFunctions = {
  proposalName: {
    asc: asc("proposalName"),
    desc: desc("proposalName"),
  },
  institutionName: {
    asc: asc("institutionName"),
    desc: desc("institutionName"),
  },
};

const sortOptions = getSortOptions(sortFunctions);

/** @type {SortState} */
const initialSortState = {
  onProperty: "proposalName",
  order: "asc",
};

function useSorts() {
  const [sort, setSort] = useState(initialSortState);

  /**
   * @param {PropertiesToSort} property
   */
  function sortOnProperty(property) {
    setSort((prev) => ({
      ...prev,
      onProperty: property,
    }));
  }

  /**
   *
   * @param {"asc" | "desc"} order
   */
  function sortByOrder(order) {
    setSort((prev) => ({
      ...prev,
      order,
    }));
  }

  return {
    sort,
    sortByOrder,
    sortOnProperty,
    sortOptions,
    sortFn: sortFunctions[sort.onProperty][sort.order],
  };
}

/**
 *
 * @typedef {"proposalName" | "department"} PropertiesToSort
 *
 * @typedef {{
 *  onProperty: PropertiesToSort,
 *  order: "asc" | "desc"
 * }} SortState
 *
 * @typedef {ReturnType<typeof useSorts>} UseSortsReturnType
 *
 */

function ResultsView({ results }) {
  return (
    <Stack spacing={1} style={{ overflowY: "scroll" }} flexGrow={1}>
      {results.map((result) => (
        <Box
          key={result.id}
          sx={{
            transition: "all 250ms ease",
            "&:hover": {
              backgroundColor: "#eee",
            },
          }}
          px=".75rem"
        >
          <Link
            to={`/details/${result.id}`}
            style={{ textDecoration: "none", color: "#333" }}
          >
            <Stack direction="row" spacing={2} alignItems="center" py={2}>
              <img
                src={result.urlAvatar}
                alt="institucion"
                width={60}
                style={{ objectFit: "contain" }}
              />
              <Box flexGrow={1}>
                <Typography
                  textOverflow="ellipsis"
                  whiteSpace="normal"
                  fontWeight={600}
                  mt=".5rem"
                >
                  {result.proposalName}
                </Typography>
                <Typography
                  textOverflow="ellipsis"
                  whiteSpace="normal"
                  color="GrayText"
                >
                  {result.institutionName}
                </Typography>
                <Typography fontSize=".675rem" color="GrayText">
                  {result.department}
                </Typography>
              </Box>
            </Stack>
          </Link>
        </Box>
      ))}
    </Stack>
  );
}

function EmptySearch({ message = "Todavía no has realizado una búsqueda" }) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      gap="1rem"
    >
      <Typography
        variant="h2"
        fontSize="1rem"
        color="GrayText"
        textAlign="center"
      >
        {message}
      </Typography>
      <img
        src={empty}
        alt="imagen decorativa"
        style={{ maxWidth: "100%", opacity: 0.75 }}
      />
    </Box>
  );
}
