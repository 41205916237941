import { Typography, createTheme, ThemeProvider } from "@mui/material";
import Button from "@mui/material/Button";
import React from "react";
import Navbar from "../components/Navbar";
import "../styles/styles.css";
import slide1 from "../../src/assets/img/slide-1.png";
import slide2 from "../../src/assets/img/slide-2.png";
import slide3 from "../../src/assets/img/slide-3.png";
import slide4 from "../../src/assets/img/slide-4.png";
import slide5 from "../../src/assets/img/slide-5.png";
import slide6 from "../../src/assets/img/slide-6.png";
import slide7 from "../../src/assets/img/slide-7.png";
import slide8 from "../../src/assets/img/slide-8.png";
import Footer from "../components/Footer";
import Stack from "@mui/material/Stack";

const theme = createTheme();

theme.typography.h2 = {
  color: "#000",
  fontSize: "x-large",
  marginRight: "200px",
  marginLeft: "200px",
  textAlign: "center",
  lineHeight: "1.5em",

  "@media (max-width: 490px)": {
    marginBottom: "10px",
    marginTop: "10px",
    marginRight: "50px",
    marginLeft: "50px",
    fontSize: "21px",
  },

  "@media (min-width: 992px)": {
    fontSize: "45px",
    margin: "0px",
    textAlign: "center",
  },
};

theme.typography.body1 = {
  marginBottom: "10px",
  marginTop: "50px",
  marginRight: "150px",
  marginLeft: "150px",
  fontSize: "18px",
  fontWeight: "400",
  color: "#616161",
  textAlign: "justify",

  "@media (max-width: 490px)": {
    fontSize: "15px",
    padding: "0px 5px",
    marginRight: "30px",
    marginLeft: "30px",
  },
};

const AboutUsTwo = () => {
  return (
    <>
      <div className="about-wraper">
        <Navbar color="green" selected="Quienes somos" />
        <div className="about">
          <div className="upm-container">
            <div className="upm-data">
              <ThemeProvider theme={theme}>
                <Typography variant="h2">
                  Descubre Fundación UPM: Transformando Comunidades desde hace
                  15 años.{" "}
                </Typography>
              </ThemeProvider>
              <div className="upm-text text-display">
                <ThemeProvider theme={theme}>
                  <Typography variant="body1">
                    Desde Fray Bentos, nació Fundación UPM con un propósito
                    claro: apoyar el desarrollo de la comunidad. Hoy, somos un
                    instrumento de fortalecimiento a largo plazo, impulsando
                    proyectos educativos y becas para el interior del país. En
                    los últimos tres años, hemos impactado a más de 5.000
                    personas en 100 comunidades, liderando 65 proyectos junto a
                    organizaciones líderes en sus ámbitos. Nuestra transparencia
                    y eficiencia son pilares fundamentales, con un consejo de
                    administración que vela por el buen uso del presupuesto y el
                    progreso de nuestros proyectos.
                  </Typography>
                </ThemeProvider>
              </div>
              <div className="button-about">
                <a
                  className="sites-links"
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.upm.uy/fundacion"
                >
                  <Button
                    variant="contained"
                    className="btn-green-about-2"
                    title="www.upm.uy/fundacion"
                  >
                    Más información
                  </Button>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="slide-outer">
          <div className="slide-track">
            <div className="slide-item">
              <img src={slide1} alt="foto1" className="foto-slider" />
            </div>
            <div className="slide-item">
              <img src={slide2} alt="foto1" className="foto-slider" />
            </div>
            <div className="slide-item">
              <img src={slide3} alt="foto1" className="foto-slider" />
            </div>
            <div className="slide-item">
              <img src={slide4} alt="foto1" className="foto-slider" />
            </div>
            <div className="slide-item">
              <img src={slide5} alt="foto1" className="foto-slider" />
            </div>
            <div className="slide-item">
              <img src={slide6} alt="foto1" className="foto-slider" />
            </div>
            <div className="slide-item">
              <img src={slide7} alt="foto1" className="foto-slider" />
            </div>
            <div className="slide-item">
              <img src={slide8} alt="foto1" className="foto-slider" />
            </div>

            <div className="slide-item">
              <img src={slide1} alt="foto1" className="foto-slider" />
            </div>
            <div className="slide-item">
              <img src={slide2} alt="foto1" className="foto-slider" />
            </div>
            <div className="slide-item">
              <img src={slide3} alt="foto1" className="foto-slider" />
            </div>
            <div className="slide-item">
              <img src={slide4} alt="foto1" className="foto-slider" />
            </div>
            <div className="slide-item">
              <img src={slide5} alt="foto1" className="foto-slider" />
            </div>
            <div className="slide-item">
              <img src={slide6} alt="foto1" className="foto-slider" />
            </div>
            <div className="slide-item">
              <img src={slide7} alt="foto1" className="foto-slider" />
            </div>
            <div className="slide-item">
              <img src={slide8} alt="foto1" className="foto-slider" />
            </div>
          </div>
        </div>
        <div className="about">
          <div className="upm-container">
            <div className="upm-data">
              <ThemeProvider theme={theme}>
                <Typography variant="h2">
                  Matea: Tecnología con Propósito Social{" "}
                </Typography>
              </ThemeProvider>
              <div className="upm-text text-display">
                <ThemeProvider theme={theme}>
                  <Typography variant="body1">
                    Primer empresa social de tecnología Uruguaya, fundada por el
                    Centro Educativo Providencia. Matea, crea software
                    personalizado que es estable, escalable y seguro, traduce
                    ideas en aplicaciones web y móviles que son fáciles de usar,
                    diseñadas por expertos y a la medida de los objetivos de sus
                    clientes. A su vez por medio de cursos gratuitos e
                    intensivos de programación (Bootcamps), basados en una
                    metodología de formación dual y de aprendizaje basado en
                    proyectos, Matea busca acelerar el proceso de inducción que
                    debe transitar un joven cuando se incorpora a una empresa
                    tecnológica, proceso que presenta importantes desafíos tanto
                    para el joven, como para las propias empresas.
                  </Typography>
                </ThemeProvider>
              </div>
              <div className="button-about">
                <a
                  className="sites-links"
                  target="_blank"
                  rel="noreferrer"
                  href="https://matea.social/"
                >
                  <Button
                    variant="contained"
                    className="btn-green-about-2"
                    title="https://matea.social/"
                  >
                    Sitio web de Matea
                  </Button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Stack alignItems="center" mt="2rem" pb=".5rem" textAlign="center">
        <Footer />
      </Stack>
    </>
  );
};

export default AboutUsTwo;
