import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  createTheme,
  ThemeProvider,
  Typography,
  CardMedia,
  Button,
  CardContent,
  CardActions,
  Card,
  Grid,
} from "@mui/material";
import Zoom from "@mui/material/Zoom";
import CircularProgress from "@mui/material/CircularProgress";

import "./styles.css";

import db from "../../utils/firebase";
import { doc, getDoc } from "firebase/firestore";

const theme = createTheme();

theme.typography.body1 = {
  marginBottom: "10px",
  lineheight: "1",
  fontWeight: 500,
  fontSize: "0.8rem",
  color: "#888888",
  "@media (max-width: 1200px)": {
    fontSize: "12px",
  },
};

theme.typography.body2 = {
  lineheight: "1",
  fontSize: "0.805rem",
  "@media (max-width: 1200px)": {
    fontSize: "12px",
  },
  textAlign: "justify",
};

theme.typography.h5 = {
  fontWeight: 600,
  fontSize: "1.2rem",
  lineHeight: "1",
};

const Carrousel = () => {
  const history = useHistory();
  const [results, setResults] = useState([]);
  const [display, setDisplay] = useState(false);

  const getData = async () => {
    /*number random */
    const min = 1;
    const max = 1568;
    let id1 = Math.floor(Math.random() * (max - min + 1));
    let id2 = Math.floor(Math.random() * (max - min + 1));
    let id3 = Math.floor(Math.random() * (max - min + 1));

    /*id data */
    const docRef = doc(db, "proposals-2023", `${id1}`);
    const docRef2 = doc(db, "proposals-2023", `${id2}`);
    const docRef3 = doc(db, "proposals-2023", `${id3}`);
    const docSnap = await getDoc(docRef);
    const docSnap2 = await getDoc(docRef2);
    const docSnap3 = await getDoc(docRef3);

    /*verify and add array */
    if (docSnap.exists() && docSnap2.exists() && docSnap3.exists()) {
      const resultArray = [];
      resultArray.push({ id: docSnap.id, ...docSnap.data() });
      resultArray.push({ id: docSnap2.id, ...docSnap2.data() });
      resultArray.push({ id: docSnap3.id, ...docSnap3.data() });
      setResults(resultArray);
    } else {
      console.log("No hay resultados");
    }
    setDisplay(true);
  };

  useEffect(() => {
    getData();
  }, []);

  const moreInformationPage = (_, result) => {
    history.push({
      pathname: `details/${result.id}`,
      state: {
        proposal: result,
      },
    });
  };

  return (
    <div className="cards-container">
      {results.length > 0 ? (
        <ThemeProvider theme={theme}>
          <Grid container>
            <Grid
              item
              xs={12}
              md={12}
              xl={12}
              sm={4}
              display="flex"
              flexDirection={{
                xs: "column",
                sm: "column",
                md: "row",
                xl: "row",
              }}
            >
              {results.map((result, id) => (
                <div key={id}>
                  <Zoom in={display} timeout={600}>
                    <Card className="card-body">
                      <CardMedia
                        component="img"
                        height="90"
                        image={result.urlPhoto}
                        alt="logo institucion"
                      />
                      <CardContent className="card-content">
                        <Typography
                          gutterBottom
                          variant="h5"
                          component="div"
                          sx={{ height: "auto" }}
                        >
                          <p className="p-card" id={1}>
                            {" "}
                            {result.proposalName}
                          </p>
                        </Typography>
                        <Typography variant="body1" color="text.secondary">
                          {result.institutionName} - {result.department}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {`${result.description}`
                            .split(" ")
                            .slice(0, 20)
                            .join(" ")}
                        </Typography>
                      </CardContent>
                      <CardActions>
                        <Button
                          size="medium"
                          variant="contained"
                          className="btn-green"
                          onClick={(e) => moreInformationPage(e, result)}
                        >
                          Más información
                        </Button>
                      </CardActions>
                    </Card>
                  </Zoom>
                </div>
              ))}
            </Grid>
          </Grid>
        </ThemeProvider>
      ) : (
        <div className="cards-loading">
          <CircularProgress color="inherit" />
        </div>
      )}
    </div>
  );
};

export default Carrousel;
