import normalizeString from "..";
/** @type {<T>(val: T): T} */
function normalizeIfString(val) {
  return typeof val === "string" ? normalizeString(val) : val;
}

export const asc = (key) => (a, b) => {
  const compareA = normalizeIfString(a[key]);
  const compareB = normalizeIfString(b[key]);
  return compareA > compareB ? 1 : compareA < compareB ? -1 : 0;
};
export const desc = (key) => (a, b) => {
  const compareA = normalizeIfString(a[key]);
  const compareB = normalizeIfString(b[key]);
  return compareA > compareB ? -1 : compareA < compareB ? 1 : 0;
};

export const alias = {
  proposalName: "Nombre de la propuesta",
  department: "Departamento",
  institutionName: "Nombre de la institución",
  durationSort: "Duración",
  asc: "Ascendente",
  desc: "Descendente",
};

export function getSortOptions(sortFunctions) {
  return Object.fromEntries(
    Object.entries(sortFunctions).map(([key, val]) => [key, Object.keys(val)])
  );
}
