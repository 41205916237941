import { createContext } from "react";

/**
 * @typedef {import("./Provider").QueryConstraints} QueryConstraints
 */

export const queryContext = createContext({
  /**
   * @type {QueryConstraints}
   */
  constraints: {},
  /**
   * @param {keyof QueryConstraints} constraint
   * @param {string | null} value;
   * */
  setConstraint: (constraint, value) => {},
  /**
   * @type {import("firebase/firestore").QueryConstraint[]}
   */
  getQueryConstraints: [],
  /**
   * @type {Record<string, Record<string, string[]>> | null}
   * */
  preFilters: null,
  /**
   * @type {Record<keyof QueryConstraints, string[]>}
   */
  options: {},
  loadingPreFilters: true,
  loadingConstraintsFromSession: true,
});
