import React, { useContext, useState } from "react";

import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Stack from "@mui/material/Stack";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

import logoMiFuturo from "../../assets/img/LogoMiFuturo.png";
import logoMiFuturoJustBook from "../../assets/img/mi_futuro_logo_just_book.png";
import { Link, useHistory } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Close from "@material-ui/icons/Close";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { contactModalCtx } from "../../context/contact-modal";

const navItems = [
  {
    label: "Búsqueda",
    href: "/",
  },
  {
    label: "Quienes somos",
    href: "/nosotros",
  },
];

const colors = {
  white: {
    default: "#ffffffbb",
    selected: "#fff",
  },
  green: {
    default: "#7cb936dd",
    selected: "#7cb936",
  },
};

/**
 *
 * @param {{
 *   color?: "white" | "green",
 *   selected?: "Búsqueda" | "Quienes somos",
 *   showLogo?: boolean,
 *   showBackButton?: boolean
 * }} props
 * @returns
 */
const Navbar = ({
  color = "white",
  selected,
  showLogo = false,
  showBackButton = false,
}) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const isLargeScreen = useMediaQuery("(min-width: 1200px)");
  const isSmallScreen = useMediaQuery("(max-width: 768px)");
  const { open: contactModalOpen, setOpen: setContactModalOpen } =
    useContext(contactModalCtx);
  const history = useHistory();

  const openDrawer = () => setDrawerOpen(true);
  const closeDrawer = () => setDrawerOpen(false);

  const isSelected = (label) => label === selected;

  const backButtonCanBeShown = !isLargeScreen && showBackButton && !showLogo;

  return (
    <>
      {/* NAV */}
      <Box
        width={{ xs: "80%", sm: "75%", lg: "80%" }}
        py={{ xs: "0.25rem", sm: "1.75rem" }}
        marginX={"auto"}
      >
        <Stack
          direction={{ xs: "row" }}
          justifyContent={
            showLogo || backButtonCanBeShown ? "space-between" : "flex-end  "
          }
        >
          {showLogo && (
            <Box alignSelf={{ xs: "center", md: "center" }}>
              <Link to="/">
                <img
                  src={isSmallScreen ? logoMiFuturoJustBook : logoMiFuturo}
                  alt="Ir al inicio"
                  width={isSmallScreen ? "45px" : "180px"}
                />
              </Link>
            </Box>
          )}
          {backButtonCanBeShown && (
            <Box alignSelf="center">
              <IconButton onClick={(_) => history.goBack()}>
                <ArrowBackIcon style={{ color: "#7cb936" }} />
              </IconButton>
            </Box>
          )}
          <Box alignSelf={"center"}>
            {!isLargeScreen ? (
              <IconButton onClick={openDrawer}>
                <MenuIcon
                  fontSize="large"
                  style={{ color: colors[color].default }}
                />
              </IconButton>
            ) : (
              <Stack
                direction="row"
                spacing={2}
                alignItems="center"
                minHeight="57px"
              >
                {navItems.map(({ href, label }) => (
                  <Box
                    key={label}
                    style={{
                      color:
                        isSelected(label) && !contactModalOpen
                          ? colors[color].selected
                          : colors[color].default,
                      fontWeight:
                        isSelected(label) && !contactModalOpen ? 500 : "normal",
                    }}
                    sx={{
                      "&:hover": {
                        transform: "scale(1.035)",
                      },
                    }}
                  >
                    <Link
                      to={href}
                      key={label}
                      style={{
                        color: "inherit",
                        transition: "250ms ease",
                        textDecoration: "none",
                      }}
                    >
                      {label}
                    </Link>
                  </Box>
                ))}
                <Box
                  sx={{
                    "&:hover": {
                      opacity: 0.8,
                      transform: "scale(1.035)",
                    },
                  }}
                >
                  <Button
                    variant="text"
                    onClick={() => setContactModalOpen(true)}
                    sx={{ fontWeight: "normal" }}
                    style={{
                      textTransform: "none",
                      color: contactModalOpen
                        ? colors[color].selected
                        : colors[color].default,
                      fontWeight: contactModalOpen ? 500 : "normal",
                      fontSize: "1rem",
                      lineHeight: "1",
                      padding: 0,
                      verticalAlign: "inherit",
                    }}
                  >
                    Contacto
                  </Button>
                </Box>
              </Stack>
            )}
          </Box>
        </Stack>
      </Box>
      {/* DRAWER */}
      {drawerOpen && !isLargeScreen && (
        <Drawer open={drawerOpen} onClose={closeDrawer} anchor="right">
          <Box>
            <List>
              <ListItem sx={{ px: 0 }}>
                <Button onClick={closeDrawer} sx={{ width: "100%" }}>
                  <Close style={{ color: "#7cb936" }} />
                </Button>
              </ListItem>
              {navItems.map(({ href, label }) => (
                <ListItem
                  key={label}
                  sx={{
                    "&:hover": {
                      transform: "scale(1.035)",
                      backgroundColor: "rgba(25, 118, 210, 0.04)",
                    },
                    p: 0,
                    m: 0,
                  }}
                >
                  <Link
                    to={href}
                    key={label}
                    style={{
                      color: "inherit",
                      transition: "all 250ms ease",
                      textDecoration: "none",
                      width: "100%",
                      padding: ".5rem 3rem",
                      textAlign: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        color:
                          selected === label && !contactModalOpen
                            ? "#7cb936"
                            : "#888",
                      }}
                      fontWeight={600}
                    >
                      {label}
                    </Typography>
                  </Link>
                </ListItem>
              ))}
              <ListItem sx={{ px: 0 }}>
                <Button
                  variant="text"
                  onClick={() => setContactModalOpen(true)}
                  sx={{
                    p: ".5rem 3rem !important",
                    transition: "all 250ms ease",
                    width: "100%",
                    "&:hover": {
                      transform: "scale(1.035)",
                    },
                  }}
                >
                  <Typography
                    sx={{ color: contactModalOpen ? "#7cb936" : "#888" }}
                    fontWeight={600}
                    style={{ textTransform: "none" }}
                  >
                    Contacto
                  </Typography>
                </Button>
              </ListItem>
            </List>
          </Box>
        </Drawer>
      )}
    </>
  );
};

export default Navbar;
