import React from "react";
import Navbar from "../components/Navbar";
import { TextField, Button } from "@mui/material/";
import "../styles/styles.css";

const UPM_EMAIL = "fundacion@upm.com";

const Contact = () => {
    return (
        <div>
            <Navbar color="black" icon="green" selected="contact" />
            <div className="page-contact">
                <div className="content_form-contact">
                    <form className="form-contact" action={`https://formsubmit.co/${UPM_EMAIL}`} method="POST">
                        <input type="hidden" name="_template" value="table"></input>
                        <input type="hidden" name="_subject" value="TU FUTURO, alguien se quiere comunicar contigo!"></input>
                        <input type="hidden" name="_captcha" value="false"></input>
                        <h1 className="form-contact_title">Comunícate con nosotros</h1>
                        <TextField
                            id="outlined-textarea"
                            label="Nombre: "
                            placeholder="Escriba su nombre"
                            multiline
                            color="success"
                            className="textfield"
                            margin="dense"
                            name="Nombre"
                        />
                        <TextField
                            id="outlined-textarea"
                            label="Localidad: "
                            placeholder="Localidad"
                            multiline
                            color="success"
                            className="textfield"
                            margin="dense"
                            name="Localidad"
                        />
                        <TextField
                            id="outlined-textarea"
                            label="Correo electrónico: "
                            placeholder="correo@ejemplo.com"
                            multiline
                            color="success"
                            className="textfield"
                            margin="dense"
                            name="Email"
                        />
                        <TextField
                            id="outlined-textarea"
                            label="Mensaje: "
                            placeholder="Escriba su mensaje"
                            multiline
                            rows={7}
                            className="textfield"
                            color="success"
                            margin="dense"
                            name="Mensaje"
                        />
                        <div style={{ margin: "10px" }}>
                            <Button sx={{marginLeft: "0px !important"}}variant="contained" type="submit" color="success" className="btn-green-contact">
                                Enviar
                            </Button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Contact;
