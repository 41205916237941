import React from "react";

import NoResultsImg from "../../assets/img/NoResults.png" 
import "./styles.css";

const NoResults = ({ navigateBack }) => (
  <div className="noResults-container">
    <div className="noResults-data-container">
      <img src={NoResultsImg} alt="sad face" />
      <h1 className="noResults-data-container__title">
        Ups, esto es raro, no encontramos ninguna propuesta
      </h1>
      <h2 className="noResults-data-container__subtitle">
        Probá de nuevo cambiando los filtros
      </h2>
      <button onClick={navigateBack} className="noResults-data-container__link">volver al inicio</button>
    </div>
  </div>
);

export default NoResults;
