import React from "react";
import Skeleton from '@mui/material/Skeleton';

import "./styles.css";

const Loading = () => {
  return (
    <div className="loading-container">
      <div className="loading-img-container"><Skeleton variant="circular" width={80} height={80} className="loading-avatar"/></div>
      <div className="loading-data-container">
      <Skeleton className="loading-title"/>
      <Skeleton className="loading-subtitle"/>
      <br />
      <Skeleton className="loading-detail"/>
      </div>
    </div>
  );
};

export default Loading;
