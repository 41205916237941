import React from "react";
import Button from "@mui/material/Button";
import { useHistory, useParams } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import preLoad from "../assets/img/PreLoad.jpg";
import Typography from "@mui/material/Typography";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PhoneEnabledIcon from "@mui/icons-material/PhoneEnabled";
import InsertLinkOutlinedIcon from "@mui/icons-material/InsertLinkOutlined";
import LocationOnIcon from "@mui/icons-material/LocationOn";

import Navbar from "../components/Navbar";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useState } from "react";
import { useEffect } from "react";
import { doc, getDoc } from "firebase/firestore";
import db from "../utils/firebase";

let isFirstTime = true;

const MoreInformation = (props) => {
  const { id } = useParams();
  const [proposalFallback, setProposalFallback] = useState(null);
  const [loadingFallback, setLoadingFallback] = useState(false);
  const history = useHistory();

  const navigateHome = () => {
    history.push({
      pathname: "/",
    });
  };

  const proposal = props?.location?.state?.proposal;

  useEffect(() => {
    if (!proposal && id) {
      let error = null;
      setLoadingFallback(true);
      getDoc(doc(db, `proposals-2023/${id}`))
        .then((doc) => {
          setProposalFallback({
            id: doc.id,
            ...doc.data(),
          });
        })
        .catch((err) => {
          console.error(err);
          error = 1;
          navigateHome();
        })
        .finally(() => {
          if (!error) {
            setLoadingFallback(false);
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isFirstTime) {
      setLoadingFallback(true);
      getDoc(doc(db, `proposals-2023/${id}`))
        .then((doc) => {
          setProposalFallback({
            id: doc.id,
            ...doc.data(),
          });
        })
        .catch(console.error)
        .finally(() => setLoadingFallback(false));
    }
    isFirstTime = false;
  }, [id, setProposalFallback, setLoadingFallback]);

  if (proposal) {
    return (
      <>
        <Navbar color="green" showBackButton />
        <ProposalView proposal={proposal} navigateHome={navigateHome} />
      </>
    );
  }

  if (!proposal && !id) {
    return (
      <>
        <Navbar color="green" showBackButton />
        <div className="detail-container">
          <h2>No has indicado ninguna propuesta</h2>
          <Button
            className="btn-green"
            variant="contained"
            color="success"
            onClick={navigateHome}
          >
            Volver al inicio
          </Button>
        </div>
      </>
    );
  }

  if (!proposal && id) {
    return (
      <>
        <Navbar color="green" showBackButton />
        {loadingFallback && (
          <div className="circular-prog">
            <CircularProgress />
          </div>
        )}
        {!loadingFallback && proposalFallback && (
          <ProposalView
            proposal={proposalFallback}
            navigateHome={navigateHome}
          />
        )}
      </>
    );
  }
};

export default MoreInformation;

function ProposalView({ proposal, navigateHome }) {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#7cb936",
      },
    },
  });

  const descriptionArray = proposal?.description?.split(". ");

  return (
    <>
      {proposal?.description === undefined ? (
        <div className="detail-container_error">
          <h2 className="detail-title">
            No hemos encontrado ninguna propuesta.
          </h2>
          <p className="detail-subtitle_error">
            Puedes volver a intentarlo, redirígete al inicio y haz una busqueda
            con los filtros.
          </p>
          <Button
            className="btn-green"
            variant="contained"
            color="success"
            onClick={navigateHome}
          >
            Volver al inicio
          </Button>
        </div>
      ) : (
        <div className="detail-container">
          <div className="detail-detail">
            <h1 className="detail-title">{proposal.proposalName}</h1>
            <h2 className="detail-subtitle">
              {proposal.institutionName}
              {proposal.institutionName !== proposal.depends
                ? ` - ${proposal.depends}`
                : ""}
            </h2>
            <div className="divider-gray"></div>
            <div className="img-wraper">
              <img
                src={proposal.urlPhoto}
                alt="foto propuesta"
                width="60%"
                className="img-overlay"
              />
              <div className="circular-prog">
                <ThemeProvider theme={theme}>
                  <CircularProgress />
                </ThemeProvider>
              </div>
            </div>
            <img
              src={preLoad}
              className="pre-load"
              alt="Imagen oculta para pre-load"
            />
            {descriptionArray.map((paragraph) => (
              <p className="description-paragraph" key={Math.random()}>
                {paragraph}
              </p>
            ))}
            <p className="description-li">
              <b>Duración: </b>
              {proposal.duration}
            </p>
            <p className="description-li">
              <b>Requisitos: </b>
              {proposal.requirements}
            </p>
            <li className="li">
              {" "}
              <div className="circle">
                {" "}
                <LocationOnIcon className="white" />
              </div>{" "}
              <Typography className="contact center">
                {proposal.address}
              </Typography>
            </li>
            <li className="li">
              {" "}
              <div className="circle">
                {" "}
                <PhoneEnabledIcon className="white" />
              </div>{" "}
              <Typography className="contact center">{proposal.tel}</Typography>
            </li>
            {proposal.mail && (
              <li className="li">
                {" "}
                <div className="circle">
                  {" "}
                  <MailOutlineIcon className="white" />{" "}
                </div>{" "}
                <Typography className="contact center">
                  <a href={`mailto:${proposal.mail}`}>{proposal.mail}</a>
                </Typography>
              </li>
            )}
            {proposal.web && (
              <li className="li">
                <div className="circle">
                  {" "}
                  <InsertLinkOutlinedIcon className="white" />{" "}
                </div>{" "}
                <Typography className="contact center text-web">
                  <a href={proposal.web} target="_blank" rel="noreferrer">
                    {proposal.web}
                  </a>
                </Typography>
              </li>
            )}
            <div className="contact-link">
              <Button
                className="btn-green"
                variant="contained"
                color="success"
                onClick={navigateHome}
              >
                Volver al inicio
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
