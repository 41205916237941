import React, { useMemo, useState } from "react";

import "./styles.css";
import { useContext } from "react";
import { queryContext } from "../../context/query";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
  Fade,
  FormControl,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import TuneIcon from "@mui/icons-material/Tune";
import SortIcon from "@mui/icons-material/Sort";
import { Button } from "@material-ui/core";
import ChipsSelectionList from "../ChipsSelectionList";

/**
 * Will tell you if that constraint is selected. Why?
 * Because beign select require to check not only null
 * values, but "none" ones. And that becomes hard to
 * read
 *
 * @param {keyof import("../../context/query").QueryConstraints} constraint
 * @param {import("../../context/query").QueryConstraints} queryConstraints
 */
const didSelect = (constraint, queryConstraints) => {
  return (
    queryConstraints[constraint] !== null &&
    queryConstraints[constraint] !== "none"
  );
};

const Selection = ({ proposalCount, sortHookState }) => {
  const queryCtx = useContext(queryContext);
  const [filtersOpen, setFiltersOpen] = useState(false);
  const isLargeScreen = useMediaQuery("(min-width: 1600px)");

  const didSelectArea = didSelect("area", queryCtx.constraints);
  const didSelectGrade = didSelect("grade", queryCtx.constraints);
  const didSelectInstitution = didSelect(
    "institutionName",
    queryCtx.constraints
  );
  const didSelectDuration = didSelect("durationHandler", queryCtx.constraints);

  const departments = useMemo(
    () => (queryCtx.preFilters ? Object.keys(queryCtx.preFilters).sort() : []),
    [queryCtx]
  );

  const areas = useMemo(() => queryCtx.options.area, [queryCtx.options]);
  const grades = useMemo(() => queryCtx.options.grade, [queryCtx.options]);
  const institutions = useMemo(
    () => queryCtx.options.institutionName,
    [queryCtx.options]
  );
  const durations = useMemo(
    () => queryCtx.options.durationHandler,
    [queryCtx.options]
  );

  const selections = useMemo(
    () =>
      Object.entries(queryCtx.constraints)
        .filter(([key]) => didSelect(key, queryCtx.constraints))
        .map(([, val]) => val),
    [queryCtx.constraints]
  );

  if (!queryCtx.preFilters) return null;

  return (
    <>
      <Accordion
        style={{ boxShadow: "none" }}
        expanded={filtersOpen || isLargeScreen}
        disableGutters
        sx={{
          "&:before": {
            display: "none",
          },
        }}
      >
        <AccordionSummary style={{ width: "100%" }}>
          <Stack alignItems="flex-start" flexGrow={1}>
            <h2 className="selection-title">
              Se encontraron {proposalCount} propuestas educativas
            </h2>
            {selections.length ? (
              <ChipsSelectionList mb={2} gap={1}>
                {selections.map((selection) => (
                  <Tooltip title={selection} key={selection}>
                    <Chip
                      label={selection}
                      variant="outlined"
                      sx={{
                        borderColor: "#7cb936",
                        maxWidth: "25ch",
                        textOverflow: "ellipsis",
                        fontSize: ".675rem",
                      }}
                    />
                  </Tooltip>
                ))}
              </ChipsSelectionList>
            ) : null}
            <Stack direction="row" spacing={1}>
              {!isLargeScreen && (
                <Button
                  onClick={() => setFiltersOpen((prev) => !prev)}
                  variant="outlined"
                  style={{
                    textTransform: "none",
                    backgroundColor: filtersOpen ? "#7cb936" : "transparent",
                    color: filtersOpen ? "white" : "black",
                  }}
                  startIcon={<TuneIcon />}
                >
                  {filtersOpen ? "Ocultar Filtros" : "Mostrar Filtros"}
                </Button>
              )}
              <SortMenu sortHookState={sortHookState} />
            </Stack>
          </Stack>
        </AccordionSummary>
        <AccordionDetails style={{ padding: 0 }}>
          <h1 className="selection-subtitle">Tu selección</h1>
          <div className="chips-container">
            <div className="filters-grid">
              {/* DEPARTAMENTOS */}
              <FormControl variant="filled">
                <InputLabel
                  id="all_departments"
                  className="input-label text-white"
                >
                  Departamento
                </InputLabel>
                <Select
                  value={queryCtx.constraints.department}
                  name="department"
                  onChange={(e) => {
                    queryCtx.setConstraint("department", e.target.value);
                  }}
                  className="small_pill green-bg"
                  disableUnderline
                >
                  {departments.map((dep) => (
                    <MenuItem value={dep} key={dep}>
                      {dep}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {/* AREAS */}
              <FormControl variant="filled">
                <InputLabel
                  id="all_areas"
                  className={`input-label ${didSelectArea ? "text-white" : ""}`}
                >
                  Área
                </InputLabel>

                <Select
                  value={queryCtx.constraints.area ?? "none"}
                  name="area"
                  onChange={(e) => {
                    queryCtx.setConstraint("area", e.target.value);
                  }}
                  labelId="all_areas"
                  className={
                    didSelectArea
                      ? "small_pill green-bg"
                      : "small_pill transparent-bg"
                  }
                  disableUnderline
                  disabled={!hasMoreThanOneOption(areas)}
                  renderValue={
                    hasMoreThanOneOption(areas)
                      ? undefined
                      : (_) => <RenderUnique value={areas[0]} />
                  }
                >
                  <MenuItem value="none" defaultChecked>
                    Todas las areas
                  </MenuItem>
                  {areas.map((area) => (
                    <MenuItem value={area} key={area}>
                      {area}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {/* GRADOS */}
              <FormControl variant="filled">
                <InputLabel
                  id="all_grades"
                  className={`input-label ${
                    didSelectGrade ? "text-white" : ""
                  }`}
                >
                  Grado
                </InputLabel>

                <Select
                  value={queryCtx.constraints.grade ?? "none"}
                  name="grade"
                  onChange={(e) => {
                    queryCtx.setConstraint("grade", e.target.value);
                  }}
                  labelId="all_grades"
                  className={
                    didSelectGrade
                      ? "small_pill green-bg"
                      : "small_pill transparent-bg"
                  }
                  disableUnderline
                  disabled={!hasMoreThanOneOption(grades)}
                  renderValue={
                    hasMoreThanOneOption(grades)
                      ? undefined
                      : (_) => <RenderUnique value={grades[0]} />
                  }
                >
                  <MenuItem value="none">Todos los grados</MenuItem>
                  {grades.map((grade) => (
                    <MenuItem value={grade} key={grade}>
                      {grade}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {/* INSTITUCIONES */}
              <FormControl variant="filled">
                <InputLabel
                  id="all_institutions"
                  className={`input-label ${
                    didSelectInstitution ? "text-white" : ""
                  }`}
                >
                  Instituciones
                </InputLabel>
                <Select
                  value={queryCtx.constraints.institutionName ?? "none"}
                  name="institution"
                  onChange={(e) => {
                    queryCtx.setConstraint("institutionName", e.target.value);
                  }}
                  labelId="all_institutions"
                  className={
                    didSelectInstitution
                      ? "small_pill green-bg"
                      : "small_pill transparent-bg"
                  }
                  disableUnderline
                  disabled={!hasMoreThanOneOption(institutions)}
                  renderValue={
                    hasMoreThanOneOption(institutions)
                      ? undefined
                      : (_) => <RenderUnique value={institutions[0]} />
                  }
                >
                  <MenuItem value="none">Todas las instituciones</MenuItem>
                  {institutions.map((institution) => (
                    <MenuItem key={institution} value={institution}>
                      {institution}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {/* DURACIONES */}
              <FormControl variant="filled">
                <InputLabel
                  id="all_durations"
                  className={`input-label ${
                    didSelectDuration ? "text-white" : ""
                  }`}
                >
                  Duración
                </InputLabel>
                <Select
                  value={queryCtx.constraints.durationHandler ?? "none"}
                  name="durationHandler"
                  onChange={(e) => {
                    queryCtx.setConstraint("durationHandler", e.target.value);
                  }}
                  labelId="all_durations"
                  className={
                    didSelectDuration
                      ? "small_pill green-bg"
                      : "small_pill transparent-bg"
                  }
                  disableUnderline
                  disabled={!hasMoreThanOneOption(durations)}
                  renderValue={
                    hasMoreThanOneOption(durations)
                      ? undefined
                      : (_) => <RenderUnique value={durations[0]} />
                  }
                >
                  <MenuItem value="none">Todas las duraciones</MenuItem>
                  {durations.map((duration) => (
                    <MenuItem key={duration} value={duration}>
                      {duration}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
      <div className="divider-gray-selection"></div>
    </>
  );
};

export default Selection;

/**
 *
 * @param {string[]} arr
 * @returns {boolean}
 */
function hasMoreThanOneOption(arr) {
  return arr.length > 1;
}

function RenderUnique({ value }) {
  return (
    <Typography
      textOverflow="ellipsis"
      whiteSpace="nowrap"
      overflow="hidden"
      title="Es la única opción disponible"
    >
      {value}
    </Typography>
  );
}

/**
 *
 * @param {{sortHookState: import("../../pages/Results").UseSortsReturnType}} props
 */
function SortMenu({ sortHookState }) {
  const { sort, sortByOrder, sortOnProperty, sortOptions } = sortHookState;
  const [anchorEl, setAnchorEl] = useState(null);
  const sortMenuOpen = Boolean(anchorEl);

  return (
    <>
      <Button
        onClick={(e) => {
          setAnchorEl(e.currentTarget);
        }}
        style={{ textTransform: "none" }}
        startIcon={<SortIcon />}
        variant="outlined"
      >
        Ordenar
      </Button>
      <Menu
        open={sortMenuOpen}
        onClose={() => setAnchorEl(null)}
        anchorEl={anchorEl}
        TransitionComponent={Fade}
        PaperProps={{
          sx: {
            width: "20rem",
          },
        }}
        MenuListProps={{
          sx: {
            p: 0,
          },
        }}
      >
        <MenuItem>
          <FormControl fullWidth variant="filled" color="success">
            <InputLabel id="onProperty" sx={{ marginLeft: 0 }}>
              Ordenar por:
            </InputLabel>
            <Select
              onChange={(e) => sortOnProperty(e.target.value)}
              value={sort.onProperty}
              labelId="onProperty"
              disableUnderline
              style={{ backgroundColor: "transparent" }}
            >
              {Object.keys(sortOptions).map((property) => (
                <MenuItem key={property} value={property}>
                  {alias[property]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </MenuItem>
        <MenuItem>
          <FormControl fullWidth variant="filled" color="success">
            <InputLabel sx={{ marginLeft: 0 }}>Orden: </InputLabel>
            <Select
              onChange={(e) => sortByOrder(e.target.value)}
              value={sort.order}
              style={{ backgroundColor: "transparent" }}
              disableUnderline
            >
              {sortOptions[sort.onProperty].map((order) => (
                <MenuItem key={order} value={order}>
                  {alias[order]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </MenuItem>
      </Menu>
    </>
  );
}

const alias = {
  proposalName: "Nombre de la propuesta",
  durationSort: "Duración",
  asc: "Ascendente",
  desc: "Descendente",
};
