import { useContext, useMemo } from "react";

import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import SearchIcon from "../../assets/img/search.svg";
import "./styles.css";

import Grid from "@mui/material/Grid";

import { queryContext } from "../../context/query";

const Filters = ({ nextPage }) => {
  const queryCtx = useContext(queryContext);

  const areas = useMemo(() => queryCtx.options.area, [queryCtx.options]);
  const grades = useMemo(() => queryCtx.options.grade, [queryCtx.options]);

  return (
    <Grid
      container
      columns={{ xs: 12 }}
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Grid
        item
        xs={12}
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection={{ xs: "column" }}
      >
        <form className="filters-form" onSubmit={(e) => nextPage(e)}>
          <div className="filters-container">
            <div className="select-container-first">
              <InputLabel className="inputLabel" sx={{ margin: 0 }}>
                ¿Dónde querés estudiar?
              </InputLabel>
              <Select
                labelId="department"
                className="select-filter"
                sx={{ fontWeight: 700 }}
                variant="standard"
                label="departamento"
                name="department"
                value={queryCtx.constraints.department ?? "none"}
                onChange={(e) =>
                  queryCtx.setConstraint("department", e.target.value)
                }
                disableUnderline
                required
              >
                <MenuItem value="none" disabled>
                  Departamento
                </MenuItem>
                <MenuItem value="Artigas">Artigas</MenuItem>
                <MenuItem value="Canelones">Canelones</MenuItem>
                <MenuItem value="Cerro Largo">Cerro Largo</MenuItem>
                <MenuItem value="Colonia">Colonia</MenuItem>
                <MenuItem value="Durazno">Durazno</MenuItem>
                <MenuItem value="Flores">Flores</MenuItem>
                <MenuItem value="Florida">Florida</MenuItem>
                <MenuItem value="Lavalleja">Lavalleja</MenuItem>
                <MenuItem value="Maldonado">Maldonado</MenuItem>
                <MenuItem value="Montevideo">Montevideo</MenuItem>
                <MenuItem value="Paysandú">Paysandú</MenuItem>
                <MenuItem value="Río Negro">Río Negro</MenuItem>
                <MenuItem value="Rivera">Rivera</MenuItem>
                <MenuItem value="Rocha">Rocha</MenuItem>
                <MenuItem value="Salto">Salto</MenuItem>
                <MenuItem value="San José">San José</MenuItem>
                <MenuItem value="Soriano">Soriano</MenuItem>
                <MenuItem value="Tacuarembó">Tacuarembó</MenuItem>
                <MenuItem value="Treinta y Tres">Treinta y Tres</MenuItem>
                <MenuItem value="Virtual">Virtual</MenuItem>
              </Select>
            </div>
            <div className="select-container">
              <InputLabel className="inputLabel" sx={{ margin: 0 }}>
                ¿En qué área?
              </InputLabel>
              <Select
                className="select-filter"
                sx={{ fontWeight: 700 }}
                variant="standard"
                name="grade"
                value={queryCtx.constraints.area ?? "none"}
                onChange={(e) => queryCtx.setConstraint("area", e.target.value)}
                disableUnderline
              >
                <MenuItem value="none">Todos las áreas</MenuItem>
                {areas.map((area, index) => (
                  <MenuItem key={index} value={area}>
                    {area}
                  </MenuItem>
                ))}
              </Select>
            </div>
            <div className="select-container-last">
              <InputLabel className="inputLabel" sx={{ margin: 0 }}>
                ¿En qué grado?
              </InputLabel>
              <Select
                value={queryCtx.constraints.grade ?? "none"}
                onChange={(e) =>
                  queryCtx.setConstraint("grade", e.target.value)
                }
                labelId="grade"
                className="select-filter"
                sx={{ fontWeight: 700 }}
                variant="standard"
                label="grado"
                name="grade"
                disableUnderline
              >
                <MenuItem value="none">Todos los grados</MenuItem>
                {grades.map((grade) => (
                  <MenuItem key={grade} value={grade}>
                    {grade}
                  </MenuItem>
                ))}
              </Select>
            </div>
            <Grid
              className="Home_container-button"
              display={{ xs: "none", md: "flex", xl: "flex" }}
            >
              <button className="filters-button" type="submit">
                <img
                  className="filters-img"
                  src={SearchIcon}
                  alt="search icon"
                />
              </button>
            </Grid>
          </div>
          <Grid
            item
            display={{ xs: "flex", md: "none", xl: "none" }}
            xs={12}
            justifyContent="center"
            alignItems="center"
            m={{ xs: 2, md: 2 }}
          >
            <Button
              variant="contained"
              type="submit"
              color="success"
              className="btn-green "
            >
              Buscar propuestas
            </Button>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};

export default Filters;
