import React from "react";
import {
  Switch,
  Route,
  BrowserRouter as Router,
  Redirect,
} from "react-router-dom";
import Results from "./pages/Results";
import Admin from "./pages/Admin";
import Index from "./pages/Index";
import AboutUsTwo from "./pages/About-2";
import MoreInformation from "./pages/MoreInformation";
import Contact from "./pages/Contact";
import Mantenimiento from "./pages/Mantenimiento";
import PageNotFound from "./pages/PageNotFound";

import QueryProvider from "./context/query/Provider";
import ContactModalProvider from "./context/contact-modal/Provider";
import SearchModalProvider from "./context/search/Provider";

const App = () => {
  return (
    <QueryProvider>
      <ContactModalProvider>
        <Router>
          <SearchModalProvider>
            <Switch>
              <Redirect exact from="/details" to="/"></Redirect>
              <Redirect from="/nosotros/*" to="/404"></Redirect>
              <Redirect from="/results/*" to="/404"></Redirect>
              <Redirect from="/contacto/*" to="/404"></Redirect>
              <Route path="/404" component={PageNotFound} />
              <Route path="/details/:id" component={MoreInformation} />
              <Route path="/results" component={Results} />
              <Route path="/admin" component={Admin} />
              <Route path="/nosotros" component={AboutUsTwo} />
              <Route path="/contacto" component={Contact} />
              <Route path="/mantenimiento" component={Mantenimiento} />
              <Route path="/" component={Index} />
            </Switch>
          </SearchModalProvider>
        </Router>
      </ContactModalProvider>
    </QueryProvider>
  );
};
export default App;
