import React from "react";

import "./styles.css";
import Typography from "@mui/material/Typography";

const ResultCard = ({ id, selected, selectThis, proposal }) => {
  return (
    <div
      className={
        selected ? "resultCard-container grow" : "resultCard-container"
      }
      id={id}
      onClick={() => selectThis(proposal, id)}
      tabIndex={0}
    >
      <div className="resultCard-img-container">
        <img src={proposal.urlAvatar} alt="imagen de la propuesta" />
      </div>
      <div className="resultCard-data-container">
        <Typography
          variant="h2"
          fontSize="1rem"
          fontWeight={600}
          style={{ wordBreak: "break-word" }}
        >
          {proposal.proposalName}
        </Typography>
        <h3 className="resultCard-data-container__subtitle">
          {proposal.institutionName}
        </h3>
        <p className="resultCard-data-container__details">{proposal.city}</p>
      </div>
    </div>
  );
};

export default ResultCard;
