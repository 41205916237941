import { Button } from "@mui/material";
import React from "react";
import { useHistory } from "react-router-dom";
import Navbar from "../components/Navbar";

const PageNotFound = () => {

    const history = useHistory();
    const navigateHome = () => {
        history.push({
            pathname: "/",
        });
    };

    return (
        <>
            <Navbar color="black" icon="green" selected="home" />
            <div className="detail-container_error">
                <h2 className="detail-title">Página no encontrada - Error 404</h2>
                <p className="detail-subtitle_error">Puedes volver a redirígirte</p>
                <Button
                    className="btn-green"
                    variant="contained"
                    color="success"
                    onClick={() => navigateHome()}
                >
                    Volver al inicio
                </Button>
            </div>
        </>
    )
}

export default PageNotFound;
