import { doc, getDoc, where } from "firebase/firestore";
import { useState, useEffect, useMemo } from "react";
import { queryContext } from ".";
import db from "../../utils/firebase";

import findAvailableOptionsGivenCertainConstraints from "./utils/find-available-options";

/** @type QueryConstraintsMap */
const initialQueryConstraints = new Map([
  ["department", null],
  ["area", null],
  ["grade", null],
  ["institutionName", null],
  ["durationHandler", null],
]);

const defaultOptions = {
  department: [],
  area: [],
  grade: [],
  institutionName: [],
  durationHandler: [],
};

export default function QueryProvider({ children }) {
  const [queryConstraints, setQueryConstraints] = useState(
    initialQueryConstraints
  );
  const [preFilters, setPrefilters] = useState(null);
  const [loadingPreFilters, setLoadingPreFilters] = useState(true);
  const [loadingConstraintsFromSession, setLoadingConstraintsFromSession] =
    useState(true);

  /**
   * @param {keyof QueryConstraints} constraint
   * @param {string | null} value;
   * */
  const setConstraint = (constraint, value) => {
    const constraintsKeys = [...queryConstraints.entries()].map(
      ([key, _]) => key
    );
    const indexOfConstraint = constraintsKeys.findIndex(
      (qc) => qc === constraint
    );
    setQueryConstraints((prev) => {
      const newConstraints = new Map(prev); //{ ...prev };
      for (let i = 0; i < constraintsKeys.length; i++) {
        if (i > indexOfConstraint) {
          newConstraints.set(constraintsKeys[i], null);
        }
      }
      newConstraints.set(constraint, value);
      return newConstraints;
    });
  };

  const getQueryConstraints = useMemo(() => {
    const department = queryConstraints.get("department");
    const area = queryConstraints.get("area");
    const grade = queryConstraints.get("grade");
    const institutionName = queryConstraints.get("institutionName");
    const durationHandler = queryConstraints.get("durationHandler");

    const constraintsToApply = [];
    if (department !== null && department !== "none") {
      constraintsToApply.push(where("department", "==", department));
    }
    if (area !== null && area !== "none") {
      constraintsToApply.push(where("area", "array-contains", area));
    }
    if (grade !== null && grade !== "none") {
      constraintsToApply.push(where("grade", "==", grade));
    }
    if (institutionName !== null && institutionName !== "none") {
      constraintsToApply.push(where("institutionName", "==", institutionName));
    }
    if (durationHandler !== null && durationHandler !== "none") {
      constraintsToApply.push(where("durationHandler", "==", durationHandler));
    }
    return constraintsToApply;
  }, [queryConstraints]);

  useEffect(() => {
    setLoadingPreFilters(true);
    const ref = doc(db, "pre-filters", "tree");
    getDoc(ref)
      .then((doc) => {
        setPrefilters(doc.data());
      })
      .catch(console.log)
      .finally(() => setLoadingPreFilters(false));
  }, []);

  useEffect(() => {
    const constraintsOnSessionStorage = sessionStorage.getItem("upm-filters");
    const newMap = new Map(initialQueryConstraints);
    if (constraintsOnSessionStorage && window.location.pathname !== "/") {
      const parsedContraints = JSON.parse(constraintsOnSessionStorage);
      for (let key in parsedContraints) {
        newMap.set(key, parsedContraints[key]);
      }
      setQueryConstraints(newMap);
    }
    setLoadingConstraintsFromSession(false);
  }, []);

  useEffect(() => {
    sessionStorage.setItem(
      "upm-filters",
      JSON.stringify(Object.fromEntries(queryConstraints.entries()))
    );
  }, [queryConstraints]);

  const options = useMemo(
    () =>
      preFilters
        ? findAvailableOptionsGivenCertainConstraints(
            preFilters,
            queryConstraints
          )
        : defaultOptions,
    [queryConstraints, preFilters]
  );

  // This is an interface for (queryConstraints: QueryConstraints)
  // Why? Because the context works with a Map, and is easier
  // to the team to work with plain objects.
  const constraints = Object.fromEntries(queryConstraints.entries());

  return (
    <queryContext.Provider
      value={{
        constraints,
        setConstraint,
        getQueryConstraints,
        preFilters,
        options,
        loadingPreFilters,
        loadingConstraintsFromSession,
      }}
    >
      {children}
    </queryContext.Provider>
  );
}

/**
 *  @typedef {{
 *    department: string | null,
 *    area: string | null,
 *    grade: string | null,
 *    institutionName: string | null,
 *    durationHandler: string | null,
 * }} QueryConstraints
 *
 * @typedef {Map<keyof QueryConstraints, string | null>} QueryConstraintsMap
 */
