import { useState, useRef, useCallback } from "react";
import { contactModalCtx } from ".";

import Typography from "@mui/material/Typography";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";

import { IconButton, Snackbar } from "@mui/material";
import Close from "@mui/icons-material/Close";


const TARGET_EMAIL = "hola@matea.social";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "white",
  boxShadow: 24,
  p: 4,
  borderRadius: "8px",
};

export default function ContactModalProvider({ children }) {
  const [open, setOpen] = useState(false);
  return (
    <contactModalCtx.Provider value={{ open, setOpen }}>
      <Modal open={open} closeAfterTransition onClose={() => setOpen(false)}>
        <Fade in={open}>
          <Box
            sx={style}
            width={{ xs: "70%", sm: 400, lg: 650 }}
            borderLeft={{ xs: "none", lg: "150px solid #7cb936" }}
            paddingX={{ xs: "1.5rem", lg: "4rem !important" }}
            paddingBottom={{ lg: "4rem !important" }}
          >
            <Stack direction="row" justifyContent="flex-end">
              <IconButton
                aria-label="Cerrar ventana"
                onClick={(_) => setOpen(false)}
              >
                <Close style={{ color: "#7cb936" }} />
              </IconButton>
            </Stack>
            <Typography
              variant="h2"
              fontSize="2rem"
              fontWeight={500}
              mb="1rem"
              align="center"
            >
              Contáctanos
            </Typography>
            <Typography paragraph align="center">
              Si tienes alguna pregunta o comentario, estaremos encantados de
              ayudarte.
            </Typography>
            <ModalForm />
          </Box>
        </Fade>
      </Modal>
      {children}
    </contactModalCtx.Provider>
  );
}

const snackbarInitialState = {
  open: false,
  error: null,
};

function ModalForm() {
  const [sending, setSending] = useState(false);
  const [snackbar, setSnackbar] = useState(snackbarInitialState);
  /** @type {React.MutableRefObject<HTMLFormElement | null>} */
  const formRef = useRef(null);

  const send = useCallback(async () => {
    if (formRef?.current) {
      const formData = new FormData(formRef.current);
      await fetch(`https://formsubmit.co/ajax/${TARGET_EMAIL}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(Object.fromEntries(formData)),
      });
    }
  }, [formRef]);

  function onSubmit(e) {
    e.preventDefault();
    setSending(true);
    send()
      .then(() => {
        formRef?.current?.reset();
        setSnackbar({
          open: true,
          error: null,
        });
      })
      .catch((_) => {
        setSnackbar({
          open: true,
          error: true,
        });
      })
      .finally(() => setSending(false));
  }

  return (
    <>
      <form onSubmit={onSubmit} ref={formRef}>
        <input type="hidden" name="_template" value="table"></input>
        <input
          type="hidden"
          name="_subject"
          value="TU FUTURO, alguien se quiere comunicar contigo!"
        ></input>
        <input type="hidden" name="_captcha" value="false"></input>
        <Stack spacing={2}>
          <Stack
            flexDirection={{ xs: "column", lg: "row" }}
            alignItems={{ xs: "stretch", lg: "center" }}
            gap="1rem"
          >
            <TextField
              variant="filled"
              placeholder="Ingrese su nombre"
              label="Nombre"
              color="success"
              style={{ flexGrow: 1 }}
              required
              name="Nombre"
            />
            <TextField
              variant="filled"
              placeholder="Ingrese su localidad"
              label="Localidad"
              color="success"
              style={{ flexGrow: 1, marginTop: 0 }}
              required
              name="Localidad"
            />
          </Stack>
          <TextField
            variant="filled"
            placeholder="Ingrese su correo electrónico"
            label="Email"
            color="success"
            type="email"
            required
            name="Email"
          />
          <TextField
            variant="filled"
            placeholder="Ingrese su mensaje"
            label="Mensaje"
            color="success"
            multiline
            rows={3}
            required
            name="Mensaje"
          />
          <Button
            variant="contained"
            style={{
              borderRadius: "8px",
              paddingBlock: ".5rem",
              backgroundColor: "#7cb936",
            }}
            sx={{
              "&:hover": {
                opacity: 0.9,
              },
            }}
            type="submit"
          >
            {sending ? (
              <CircularProgress size="1rem" />
            ) : (
              <span style={{ color: "white" }}>Enviar</span>
            )}
          </Button>
        </Stack>
      </form>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={snackbar.open}
        autoHideDuration={5000}
        onClose={() => {
          setSnackbar((prev) => ({
            ...prev,
            open: false,
          }));
        }}
        onTransitionEnd={() => {
          if (!snackbar.open) {
            setSnackbar((prev) => ({
              ...prev,
              error: null,
            }));
          }
        }}
      >
        <Alert
          severity={snackbar?.error ? "error" : "success"}
          sx={{ width: "100%" }}
        >
          {snackbar.error
            ? "Ha ocurrido un error, vuelve a intentarlo"
            : "Mensaje enviado correctamente!"}
        </Alert>
      </Snackbar>
    </>
  );
}