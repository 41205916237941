/**
 *
 * @param {TreeNode} tree
 * @param {Constraints} constraints
 */
export default function findAvailableOptionsGivenCertainConstraints(
  tree,
  constraints
) {
  const keysOfConstraints = [...constraints.keys()];
  const response = new Map(keysOfConstraints.map((key) => [key, new Set()]));
  const maxDepth = constraints.size - 2;

  /**
   *
   * @param {{
   *  treeNode: TreeNode,
   *  atLevel: number,
   * }} params
   */
  function inspectNode({ treeNode, atLevel }) {
    const branches = Object.keys(treeNode);
    for (let branch of branches) {
      response.get(keysOfConstraints[atLevel]).add(branch);
    }

    const valueOfConstraintAtLevel = constraints.get(
      keysOfConstraints[atLevel]
    );

    if (atLevel === maxDepth) {
      if (thereIsASelectionOn(valueOfConstraintAtLevel)) {
        if (valueOfConstraintAtLevel in treeNode) {
          const branch = treeNode[valueOfConstraintAtLevel];
          for (let leaf of branch) {
            response.get(keysOfConstraints[atLevel + 1]).add(leaf);
          }
        } else return;
      } else {
        const branches = Object.values(treeNode);
        for (let branch of branches) {
          for (let leaf of branch) {
            response.get(keysOfConstraints[atLevel + 1]).add(leaf);
          }
        }
        return;
      }
    } else {
      if (thereIsASelectionOn(valueOfConstraintAtLevel)) {
        if (valueOfConstraintAtLevel in treeNode) {
          return inspectNode({
            treeNode: treeNode[valueOfConstraintAtLevel],
            atLevel: atLevel + 1,
          });
        } else return;
      } else {
        const branches = Object.keys(treeNode);
        for (let branch of branches) {
          inspectNode({
            treeNode: treeNode[branch],
            atLevel: atLevel + 1,
          });
        }
        return;
      }
    }
  }

  inspectNode({ treeNode: tree, atLevel: 0 });
  ////
  /**
   * Why an 'interface'? Because in the render fase, is
   * easier to work with plain objects and arrays
   * ```{string: string[]}```
   * than with
   * ```Map<string, Set<string>>```
   */
  const responseInterface = {};
  response.forEach((value, key) => {
    responseInterface[key] = Array.from(value).sort();
  });

  return responseInterface;
}

/**
 * This is due to MUI Select doesn't allow null values
 * so we choose 'none' as a valid null.
 *
 * @param {string | null} valueOfConstraintAtLevel
 */
function thereIsASelectionOn(valueOfConstraintAtLevel) {
  return valueOfConstraintAtLevel && valueOfConstraintAtLevel !== "none";
}

/**
 *
 * @typedef {Map<string, string | null>} Constraints
 *
 * @typedef {{
 *  [keyInLevelOfTree: string]: TreeNode | string[]
 * }} TreeNode
 */
