import React from 'react'
import "../styles/styles.css";
import logoMiFuturo2 from "../../src/assets/img/LogoMiFuturo2.png";
import logoFundacion from "../../src/assets/img/LOGO_FUPM.svg";
import logoMatea from "../../src/assets/img/LogoMatea.png";
import enConstruccion from "../../src/assets/img/enConstruccion.svg";


const Mantenimiento = () => {
  return (
    <div>
      <div className="container-2">
          <div className='container-logo'>
            <img className='logo' src={logoMiFuturo2} alt="logo mi futuro" />
          </div>    
          <div className='container-main'>
              <div className='container-texto'>
                <h1>Oops!</h1>
                <h3>Estamos en modo construcción.</h3>
                <p>Lamentamos los inconvenientes.</p>
                <p>Volveremos en breve.</p>
              </div>
              <div className='container-foto'>
                <img className='foto' src={enConstruccion} alt="foto bajo contruccion" />
              </div>
          </div>
          
      </div>
      <footer className='footer'>
          <div className='container-footer'>
            <img className='logoFundacion' src={logoFundacion} alt="logo mi futuro" />
            <img className='logoMatea' src={logoMatea} alt="logo mi futuro" />
          </div>
        </footer>
    </div>
  )
}

export default Mantenimiento;