import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../styles/styles.css";
import db from "../utils/firebase";
import { collection, addDoc } from "firebase/firestore/lite";

const Admin = () => {
  const [info, setInfo] = useState({});

  const departments = [
    "Artigas",
    "Canelones",
    "Cerro Largo",
    "Colonia",
    "Durazno",
    "Flores",
    "Florida",
    "Lavalleja",
    "Maldonado",
    "Montevideo",
    "Paysandú",
    "Río Negro",
    "Rivera",
    "Rocha",
    "Salto",
    "San José",
    "Soriano",
    "Tacuarembó",
    "Treinta y Tres",
  ];

  const clearData = () => {
    setInfo({
      nombre: "",
      institucion: "",
      departamento: "",
      subsistema: "",
      descripcion: "",
      requisitos: "",
      duracion: "",
      precio: "",
      whatsapp: "",
      telefono: "",
      web: "",
      email: "",
      direccion: "",
      tipo: "",
      avatar: "",
      foto: "",
    });
  };

  const saveProposal = async (db) => {
    try {
      await addDoc(collection(db, "proposals"), {
        name: info.nombre,
        institutionName: info.institucion,
        description: info.descripcion,
        requirements: info.requisitos || "",
        duration: info.duracion || "",
        price: info.precio,
        departamento: info.departamento,
        subsystem: info.subsistema,
        type: info.tipo,
        contact: {
          telephone: info.telefono,
          address: info.direccion,
          sitieWeb: info.web || "",
          mail: info.email || "",
          whatsapp: info.whatsapp || "",
        },
        avatarUrl: info.avatar || "",
        photoUrl: info.foto || "",
        }
      )
      clearData()
      return new Promise((resolve, reject) => resolve())
    } catch (e) {
      return new Promise((resolve, reject) => reject(console.error(e.name)));
    }
  };

  const handleChange = (e) => {
    setInfo({
      ...info,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div className="admin">
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover={false}
        width="auto"
      />
      <h1 className="title_admin">Propuestas Educativas</h1>
      <div className="container-admin">
        <form className="form">
          <div className="column">
            <label>Nombre propuesta </label>
            <input
              className="inputAdmin"
              onChange={(e) => handleChange(e)}
              name="nombre"
              value={info.nombre}
              placeholder="Nombre de propuesta"
            ></input>
            <label>Nombre institución </label>
            <input
              className="inputAdmin"
              onChange={(e) => handleChange(e)}
              name="institucion"
              value={info.institucion}
              placeholder="Nombre institución"
            ></input>
            <label>Departamento </label>
            <select
              label="departamento"
              className="inputAdmin"
              name="departamento"
              value={info.departamento}
              onChange={(e) => handleChange(e)}
            >
              <option key="0" value="selected">
                Seleccioná Departamento
              </option>
              {departments.map((department) => (
                <option key={Math.random()} value={department}>
                  {department}
                </option>
              ))}
            </select>
            <label>Subsistema </label>
            <select
              className="inputAdmin"
              name="subsistema"
              value={info.subsistema}
              onChange={(e) => handleChange(e)}
            >
              <option>Seleccióna Subsistema</option>
              <option value="Utu">Utu</option>
              <option value="Secundaria">Secundaria</option>
              <option value="Universidad">Universidad</option>
            </select>
            <label>Costo </label>
            <select
              className="inputAdmin"
              name="precio"
              value={info.precio}
              onChange={(e) => handleChange(e)}
            >
              <option>Seleccióna Costo</option>
              <option value="Pago">Pago</option>
              <option value="Gratis">Gratis</option>
            </select>
            <label>Requisitos </label>
            <input
              className="inputAdmin"
              onChange={(e) => handleChange(e)}
              name="requisitos"
              value={info.requisitos}
              placeholder="Requisitos"
            ></input>
            <label>Duración </label>
            <input
              className="inputAdmin"
              onChange={(e) => handleChange(e)}
              name="duracion"
              value={info.duracion}
              placeholder="Duración"
            ></input>
            <label>Descripción </label>
            <textarea
              onChange={(e) => handleChange(e)}
              name="descripcion"
              value={info.descripcion}
              placeholder="Descripción"
            ></textarea>
          </div>
          <div className="column">
            <label>tipo </label>
            <input
              className="inputAdmin"
              onChange={(e) => handleChange(e)}
              name="tipo"
              value={info.tipo}
              placeholder="tipo: Carrera, Curso, Taller"
            ></input>
            <label>Teléfono </label>
            <input
              className="inputAdmin textearea"
              onChange={(e) => handleChange(e)}
              name="telefono"
              value={info.telefono}
              placeholder="Telefono"
            ></input>
            <label>Whatsapp </label>
            <input
              className="inputAdmin"
              onChange={(e) => handleChange(e)}
              name="whatsapp"
              value={info.whatsapp}
              placeholder="Whatsapp"
            ></input>
            <label>Email </label>
            <input
              className="inputAdmin"
              onChange={(e) => handleChange(e)}
              name="email"
              value={info.email}
              placeholder="Email"
            ></input>
            <label>Sitio Web </label>
            <input
              className="inputAdmin"
              onChange={(e) => handleChange(e)}
              name="web"
              value={info.web}
              placeholder="Sitio web"
            ></input>
            <label>Dirección </label>
            <input
              className="inputAdmin"
              onChange={(e) => handleChange(e)}
              name="direccion"
              value={info.direccion}
              placeholder="Dirección"
            ></input>
            <label>Url foto banner </label>
            <input
              className="inputAdmin"
              onChange={(e) => handleChange(e)}
              name="foto"
              value={info.foto}
              placeholder="URL PHOTO BANNER"
            ></input>
            <label>Url Avatar </label>
            <input
              className="inputAdmin"
              onChange={(e) => handleChange(e)}
              name="avatar"
              value={info.avatar}
              placeholder="URL AVATAR"
            ></input>
          </div>
        </form>
        <button
          className="btn_admin"
          onClick={() =>
            toast.promise(saveProposal(db), {
              pending: "Guardando...",
              success: "Oferta guardada correctamente 👌",
              error:
                "Ocurrió un error al guardar la oferta 🤯, revisa los campos e intenta de nuevo",
            })
          }
        >
          GUARDAR OFERTAS EDUCATIVAS
        </button>
      </div>
    </div>
  );
};
export default Admin;
